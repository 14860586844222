<template>
  <b-button-group size="sm">
    <slot name="prepend"></slot>
    <b-button v-if="!is_editable_" @click="$emit('get')" variant="outline-dark" :disabled="disabled"><i class="fas fa-sync-alt"></i></b-button>
    <template v-if="!is_editable_ && !is_view_only">
      <b-button @click="is_editable_ = true" variant="secondary" :disabled="disabled"> <i class="fas fa-edit"></i>&nbsp; Edit </b-button>
    </template>
    <template v-if="is_editable_ && !is_always_editable && !is_view_only">
      <b-button
        v-if="is_submittable"
        @click="
          () => {
            $emit('submit');
          }
        "
        variant="success"
        :disabled="disabled"
      >
        <i class="fas fa-check"></i>&nbsp; Submit
      </b-button>
      <b-button
        @click="
          () => {
            is_editable_ = false;
          }
        "
        variant="danger"
        :disabled="disabled"
        v-b-tooltip.hover
        title="Clear Changes"
      >
        <i class="fas fa-ban"></i>&nbsp; Cancel
      </b-button>
    </template>
    <slot></slot>
    <slot name="append"></slot>
    <!-- <b-button @click="is_editable_ = !is_editable_" variant="secondary">
      <i
        class="fas"
        :class="{'fa-unlock' : !is_editable_, 'fa-lock' : is_editable_}"
      ></i>
    </b-button>-->
  </b-button-group>
</template>

<script>
export default {
  components: {},
  props: {
    is_view_only: {
      type: Boolean,
    },
    is_editable: {
      type: Boolean,
    },
    is_always_editable: {
      type: Boolean,
      default: false,
    },
    is_submittable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    is_editable_: {
      // getter
      get: function() {
        return this.is_editable;
      },
      // setter
      set: function(newValue) {
        this.$emit('update:is_editable', newValue);
      },
    },
  },
  watch: {},
  created() {},
  methods: {},
};
</script>
